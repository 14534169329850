
import { $gettext, $ngettext } from 'vue-gettext';
import { byKey } from '@/utils/array';
import { Contact, useContactStore } from '@/stores/contact';
import { currentLanguage } from '@/translation';
import { Customer, useCustomerStore } from '@/stores/customer';
import { defineComponent, ref, watch } from 'vue';
import { json } from '@sahnee/ajax';
import { loadComponentCache, saveComponentCache } from '@/utils/component';
import { localizeDate } from '@/utils/date';
import { reloadStores } from '@/utils/store';
import { translatedUnitEnumeration } from '@/utils/string';
import { type Header, toBoolean, actionTooltip } from '@/components/table/BpTable';
import { useAppStore } from '@/stores/app';
import { useCountryStore } from '@/stores/country';
import { useEditionStore } from '@/stores/edition';
import { useHostingStatusStore } from '@/stores/hostingStatus';
import { User, useUserStore } from '@/stores/user';
import { useSupportStatusStore } from '@/stores/supportStatus';
import BpToast from '@/components/toast/BpToasts';
import router from '@/router';
import useCompactMode from '@/compositions/use-compact-mode';

export default defineComponent({
  name: 'admin.customer-overview',
  setup() {
    ///-------------------------------------------------------------------
    /// STORE
    ///-------------------------------------------------------------------

    const store = useCustomerStore(true);
    const appStore = useAppStore();
    const contactStore = useContactStore();
    const countryStore = useCountryStore();
    const editionStore = useEditionStore(true);
    const supportStatusStore = useSupportStatusStore();
    const hostingStatusStore = useHostingStatusStore();

    ///-------------------------------------------------------------------
    /// CUSTOMERS
    ///-------------------------------------------------------------------

    const customers = ref<Customer[]>([])

    const onlyActiveSupport = ref(loadComponentCache('customer_overview:only_active_support', null) ?? true);

    function updateOnlyActiveSupport(value: boolean) {
      onlyActiveSupport.value = value;
      saveComponentCache('customer_overview:only_active_support', null, value);
    }

    watch(() => [store.isLoading(), onlyActiveSupport.value], () => {
      if (!store.loaded || store.isLoading()) { return }
      if (onlyActiveSupport.value) {
        customers.value = store.customers.filter(customer => {
          const supportStatus = supportStatusStore.getByCustomer(customer);
          return ['active', 'expired_grace_period', 'pilot_support'].includes(supportStatus._id)
        });
        return;
      }
      customers.value = store.customers;
    }, { immediate: true })

    function updateCustomer(id: string | string[]) {
      if (Array.isArray(id)) {
        id = id[0];
      }
      router.push({
        name: 'admin.customer.edit',
        params: {
          id
        }
      });
    }

    async function impersonateCustomer(id: string | string[]) {
      if (Array.isArray(id)) {
        id = id[0];
      }
      const response = await json<{ success: boolean }>('/api/session-user/edit', { method: 'POST', json: { customerId: id, action: 'impersonate' } });
      if (response.success) {
        reloadStores(true);
        router.replace({
          name: 'home',
        });
      }
    }

    ///-------------------------------------------------------------------
    /// HEADERS
    ///-------------------------------------------------------------------

    const { current: compactMode } = useCompactMode();

    const headers = ref<Header[]>([]);
    watch(() => [currentLanguage.value, appStore.isLoading(), editionStore.isLoading()], () => {
      if (!appStore.loaded || appStore.isLoading() || !editionStore.loaded || editionStore.isLoading()) { return }
      headers.value = [
        {
          _id: 'company',
          name: $gettext('Company name'),
          width: '1.5fr',
        },
        {
          _id: 'customerNumber',
          name: $gettext('Customer number'),
          canCopy: true,
          monospace: true,
          notSortable: true,
          width: compactMode?.value ? undefined : '1.14fr',
        },
        {
          _id: 'registered',
          name: $gettext('Registered'),
          filter: [
            {
              _id: '_true',
              name: $gettext('Yes'),
              icon: { icon: 'check', color: 'green' },
              detect: (customer: Customer) => userStore.getByCustomer(customer).filter(user => user.email).length > 0,
            },
            {
              _id: 'false',
              name: $gettext('No'),
              icon: { icon: 'xmark', color: 'red' },
              detect: (customer: Customer) => userStore.getByCustomer(customer).filter(user => user.email).length === 0,
            }
          ],
          hidden: true,
          notSortable: true,
          transform: (customer: Customer) => userStore.getByCustomer(customer).filter(user => user.email).length > 0,
        },
        {
          _id: 'mail',
          name: $gettext('Mail'),
          hidden: true,
          transform: (customer: Customer) => userStore.getByCustomer(customer).map(user => user.email).join(', '),
        },
        {
          _id: 'supportTo',
          name: $gettext('Support to'),
          filter: supportStatusStore.supportStatuses.map(
            supportStatus => ({
              ...supportStatus,
              detect: (customer: Customer) => supportStatus._id === (supportStatusStore.getByCustomer(customer)?._id || ''),
            })
          ),
          width: '1.25fr',
          icon: (customer: Customer) => supportStatusStore.getByCustomer(customer)?.icon || '',
          transform: (customer: Customer) => supportStatusStore.getByCustomer(customer)?.name || '',
        },
        {
          _id: 'canceledTo',
          name: $gettext('Canceled to'),
          hidden: true,
          transform: (_customer: Customer, canceledTo: Customer['canceledTo']) => localizeDate(canceledTo),
        },
        {
          _id: 'dcpc',
          name: $gettext('Data processing contract'),
          filter: [
            {
              _id: '_true',
              name: $gettext('Yes'),
              icon: { icon: 'check', color: 'green' },
              detect: (customer: Customer) => !!customer.dcpc,
            },
            {
              _id: 'false',
              name: $gettext('No'),
              icon: { icon: 'xmark', color: 'red' },
              detect: (customer: Customer) => !customer.dcpc,
            }
          ],
          notSortable: true,
          transform: toBoolean,
        },
        {
          _id: 'editionProduct',
          _group: 'bestinformed',
          name: 'bestinformed<sup>®</sup>',
          filter: [
            ...[...editionStore.editions.bestinformed5, ...editionStore.editions.bestinformed6]
              .map(edition => ({
                _id: '_' + edition._id,
                name: '<span>' + editionStore.getNameOf(edition._id) + ` <em style="opacity: 0.5;">(${$gettext('Version %{version}', {
                  version: editionStore.getBestinformedVersionById(edition._id)
                })})</em></span>`,
                icon: { icon: 'check', color: 'green' },
                detect: (customer: Customer) => edition._id === store.getBestinformedEdition(customer) && store.hasProduct(customer, 'bestinformed') && !customer.pilot,
              })),
            {
              _id: '_pilot',
              name: $gettext('Pilot'),
              icon: { icon: 'check', color: 'green' },
              detect: (customer: Customer) => store.hasProduct(customer, 'bestinformed') && !!customer.pilot,
            },
            {
              _id: 'no',
              name: $gettext('No'),
              icon: { icon: 'xmark', color: 'red' },
              detect: (customer: Customer) => !store.hasProduct(customer, 'bestinformed'),
            },
          ],
          width: '2fr',
          icon: (customer: Customer) => store.hasProduct(customer, 'bestinformed')
            ? { icon: 'check', color: 'green' }
            : { icon: 'xmark', color: 'red' },
          tooltip: (customer: Customer, editionProduct: Customer['editionProduct']) => {
            if (!customer.pilot && customer.products.bestinformed.version === '6' || editionProduct === 'Apps') {
              const apps = store.getBestinformedApps(customer);
              const appPlus = appStore.apps.appPlus.map(app => {
                if (apps.includes(app._id)) {
                  return `<li class="flex gap-sm"><span style="color: var(--color-green-600); flex-shrink: 0; text-align: center; width: 1.25em;">&checkmark;</span><span>${app.name}</span></li>`
                }
                return `<li class="flex gap-sm"><span style="color: var(--color-red-600); flex-shrink: 0; text-align: center; width: 1.25em;">&cross;</span><span style="color: var(--theme-text-disabled); text-decoration: line-through;">${app.name}</span></li>`
              }
              ).join('');
              const additionalApps = appStore.apps.additionalApps.map(app => {
                if (apps.includes(app._id)) {
                  return `<li class="flex gap-sm"><span style="color: var(--color-green-600); flex-shrink: 0; text-align: center; width: 1.25em;">&checkmark;</span><span>${app.name}</span></li>`
                }
                return `<li class="flex gap-sm"><span style="color: var(--color-red-600); flex-shrink: 0; text-align: center; width: 1.25em;">&cross;</span><span style="color: var(--theme-text-disabled); text-decoration: line-through;">${app.name}</span></li>`
              }
              ).join('');
              return `
                <p>${store.getBestinformedEditionName(customer)} <em style="color: var(--theme-text-secondary);">(${$gettext('Version %{version}', { version: customer.products.bestinformed.version || (editionProduct === 'Apps' ? 6 : 5) })})</em></p>
                <p>${$gettext('App plus')}</p>
                <ul style="column-count: 2; column-gap: var(--padding); list-style-type: none; padding: 0;">${appPlus}</ul>
                <p>${$gettext('Additional apps')}</p>
                <ul style="column-count: 2; column-gap: var(--padding); list-style-type: none; padding: 0;">${additionalApps}</ul>
              `;
            }
          },
          transform: (customer: Customer, editionProduct: Customer['editionProduct']) => {
            if (!store.hasProduct(customer, 'bestinformed')) {
              return '<span style="color: var(--theme-text-disabled);">—</span>';
            }
            if (!!customer.pilot || !editionProduct) {
              return $gettext('Pilot');
            }
            return '<span>' + store.getBestinformedEditionName(customer) + ` <em style="opacity: 0.5;">(${$gettext('Version %{version}', {
              version: customer.products.bestinformed.version || (editionProduct === 'Apps' ? 6 : 5),
            })})</em></span>`;
          },
        },
        {
          _id: 'subscription',
          _group: 'bestinformed',
          filter: [
            {
              _id: '_true',
              name: $gettext('Subscription'),
              detect: (customer: Customer) => !!customer.subscription,
            },
            {
              _id: 'false',
              name: $gettext('Purchase'),
              detect: (customer: Customer) => !customer.subscription,
            }
          ],
          hidden: true,
          name: $gettext('License type'),
          notSortable: true,
          transform: (_customer: Customer, subscription: Customer['subscription']) => subscription ? $gettext('Subscription') : $gettext('Purchase'),
        },
        {
          _id: 'numberOfLicences',
          _group: 'bestinformed',
          name: $gettext('Number of licenses'),
          textAlign: 'center',
          width: '1.25fr',
          icon: (customer: Customer) => customer.unlimited_cals ? 'infinity' : '',
          transform: (customer: Customer, numberOfLicences: Customer['numberOfLicences']) => {
            if (!store.hasProduct(customer, 'bestinformed')) {
              return '';
            }
            if (customer.unlimited_cals) {
              return $gettext('Unlimited')
            }
            return !Number(numberOfLicences) ? '' : numberOfLicences
          },
        },
        {
          _id: 'mobilecals',
          _group: 'bestinformed',
          name: $gettext('Mobile CALs'),
          textAlign: 'center',
          transform: (customer: Customer, mobilecals: Customer['mobilecals']) => {
            if (!store.hasProduct(customer, 'bestinformed')) {
              return '';
            }
            return !Number(mobilecals) ? '' : mobilecals
          },
        },
        {
          _id: 'clusternodes',
          _group: 'bestinformed',
          name: $gettext('Cluster nodes'),
          textAlign: 'center',
          transform: (customer: Customer, clusternodes: Customer['clusternodes']) => {
            if (!store.hasProduct(customer, 'bestinformed')) {
              return '';
            }
            return !Number(clusternodes) ? '' : clusternodes
          },
        },
        {
          _id: 'bestproxy',
          _group: 'bestproxy',
          name: 'bestproxy',
          filter: [
            {
              _id: '_bestproxy',
              name: 'bestproxy',
              icon: { icon: 'check', color: 'green' },
              detect: (customer: Customer) => store.hasProduct(customer, 'bestproxy') && !customer.pilot,
            },
            {
              _id: '_pilot',
              name: $gettext('Pilot'),
              icon: { icon: 'check', color: 'green' },
              detect: (customer: Customer) => store.hasProduct(customer, 'bestproxy') && !!customer.pilot,
            },
            {
              _id: 'false',
              name: $gettext('No'),
              icon: { icon: 'xmark', color: 'red' },
              detect: (customer: Customer) => !store.hasProduct(customer, 'bestproxy'),
            }
          ],
          width: '1.25fr',
          // transform: toBoolean,
          icon: (customer: Customer) => store.hasProduct(customer, 'bestproxy')
            ? { icon: 'check', color: 'green' }
            : { icon: 'xmark', color: 'red' },
          transform: (customer: Customer) => {
            if (!store.hasProduct(customer, 'bestproxy')) {
              return '<span style="color: var(--theme-text-disabled);">—</span>';
            }
            if (customer.pilot) {
              return $gettext('Pilot');
            }
            return 'bestproxy';
          },
        },
        {
          _id: 'bestproxycores',
          _group: 'bestproxy',
          name: $gettext('CPU cores'),
          textAlign: 'center',
          transform: (customer: Customer, bestproxycores: Customer['bestproxycores']) => {
            if (!store.hasProduct(customer, 'bestproxy')) {
              return '';
            }
            return !Number(bestproxycores) ? '' : bestproxycores
          },
        },
        {
          _id: 'bestproxyram',
          _group: 'bestproxy',
          name: $gettext('RAM in GB'),
          textAlign: 'center',
          transform: (customer: Customer, bestproxyram: Customer['bestproxyram']) => {
            if (!store.hasProduct(customer, 'bestproxy')) {
              return '';
            }
            return !Number(bestproxyram) ? '' : bestproxyram
          },
        },
        {
          _id: 'bestproxyruntimeto',
          name: $gettext('Runtime to'),
          hidden: true,
          transform: (_customer: Customer, bestproxyruntimeto: Customer['bestproxyruntimeto']) => localizeDate(bestproxyruntimeto),
        },
        {
          _id: 'bestzero',
          _group: 'bestzero',
          filter: [
            {
              _id: '_bestproxy',
              name: 'bestzero<sup>®</sup>',
              icon: { icon: 'check', color: 'green' },
              detect: (customer: Customer) => store.hasProduct(customer, 'bestzero') && !customer.pilot,
            },
            {
              _id: '_pilot',
              name: $gettext('Pilot'),
              icon: { icon: 'check', color: 'green' },
              detect: (customer: Customer) => store.hasProduct(customer, 'bestzero') && !!customer.pilot,
            },
            {
              _id: 'false',
              name: $gettext('No'),
              icon: { icon: 'xmark', color: 'red' },
              detect: (customer: Customer) => !store.hasProduct(customer, 'bestzero'),
            }
          ],
          name: 'bestzero<sup>®</sup>',
          width: '1.25fr',
          // transform: (customer: Customer) => toBoolean(customer, store.hasProduct(customer, 'bestzero')),
          icon: (customer: Customer) => store.hasProduct(customer, 'bestzero')
            ? { icon: 'check', color: 'green' }
            : { icon: 'xmark', color: 'red' },
          transform: (customer: Customer) => {
            if (!store.hasProduct(customer, 'bestzero')) {
              return '<span style="color: var(--theme-text-disabled);">—</span>';
            }
            if (customer.pilot) {
              return $gettext('Pilot');
            }
            return 'bestzero<sup>®</sup>';
          },
        },
        {
          _id: 'bestzero_numberOfLicenses',
          _group: 'bestzero',
          name: $gettext('Connections'),
          textAlign: 'center',
          transform: (customer: Customer) => {
            if (!store.hasProduct(customer, 'bestzero')) {
              return '';
            }
            return !Number(customer.numberOfLicences) ? '' : customer.numberOfLicences
          },
        },
        {
          _id: 'hosting',
          name: $gettext('Hosting'),
          filter: hostingStatusStore.hostingStatuses.map(
            hostingStatus => ({
              ...hostingStatus,
              detect: (customer: Customer) => (hostingStatus._id === (hostingStatusStore.getByCustomer(customer)?._id || '')),
            })
          ),
          width: '1.25fr',
          hidden: true,
          icon: (customer: Customer) => hostingStatusStore.getByCustomer(customer)?.icon || '',
          transform: (customer: Customer) => hostingStatusStore.getByCustomer(customer)?.name || '',
        },
        {
          _id: 'city',
          name: $gettext('City'),
          hidden: true,
        },
        {
          _id: 'country',
          name: $gettext('Country'),
          filter: countryStore.countries.map(
            country => ({
              ...country,
              // TODO: Sort DACH countries to top of list
              // _id: country._id === 'Deutschland' ? '_0_' + country._id
              //   : country._id === 'Österreich' ? '_1_' + country._id
              //   : country._id === 'Schweiz' ? '_2_' + country._id
              //   : country._id,
              detect: (customer: Customer) => country._id === customer.country
            })
          ),
          hidden: true,
          transform: (_customer: Customer, country: Customer['country']) => countryStore.getNameOf(country),
        },
        {
          _id: 'containsReseller',
          name: $gettext('Reseller'),
          hidden: true,
          transform: (_customer: Customer, containsReseller: Customer['containsReseller']) => getResellerName(containsReseller),
        },
      ]
    }, { immediate: true });

    ///-------------------------------------------------------------------
    /// RESELLER NAME
    ///-------------------------------------------------------------------

    function getResellerName(value: string) {
      if (!value || value === 'none') {
        return '';
      }
      const customer = store.getById(value);
      if (!customer) {
        return value;
      }
      return customer.company;
    }

    ///-------------------------------------------------------------------
    /// DELETE CONFIRMATION
    ///-------------------------------------------------------------------

    const confirmation = ref(false);
    const confirmDelete = ref<{
      customers: Customer[],
      contacts: Contact[],
      users: User[]
    }>({
      customers: [],
      contacts: [],
      users: []
    });

    function showDeleteConfirmation(ids: string | string[]) {
      if (!Array.isArray(ids)) {
        ids = [ids];
      }
      const customers = store.getById(ids);
      const contacts = contactStore.getByCustomerId(ids);
      const users = userStore.getByCustomerId(ids);
      confirmDelete.value.customers = Array.isArray(customers) ? customers.sort(byKey('company')) : [customers];
      confirmDelete.value.contacts = Array.isArray(contacts) ? contacts.sort(byKey('email')) : [contacts];
      confirmDelete.value.users = Array.isArray(users) ? users.sort(byKey('email')) : [users];
      confirmation.value = true;
    }

    ///-------------------------------------------------------------------
    /// DELETE
    ///-------------------------------------------------------------------

    //list({customer: Customer[], contact: Contact[]}, opts)

    async function deleteCustomer() {
      const customerIDs = confirmDelete.value.customers.map(customer => customer._id);
      const contactIDs = confirmDelete.value.contacts.map(contact => contact._id);
      const userIDs = confirmDelete.value.users.map(user => user._id);
      const response = await store.delete(customerIDs);

      if (response?.success) {
        const customers = response.data?.filter(doc => doc.doc_type === 'customer') || [];
        const contacts = response.data?.filter(doc => doc.doc_type === 'contact') || [];
        const users = response.data?.filter(doc => doc.doc_type === 'new_contact') || [];

        if (customers && contacts && users) {
          if (customers.length === customerIDs.length && contacts.length === contactIDs.length && users.length === userIDs.length) {
            BpToast.show({
              color: 'green',
              title: $gettext('%{list} successfully deleted', { list: translatedUnitEnumeration({
                customers: [customerIDs.length, customers.length],
                contacts: [contactIDs.length, contacts.length],
                users: [userIDs.length, users.length],
              }, { includeCount: false }) }),
              content: $ngettext(
                '<strong>%{list}</strong> was successfully deleted.',
                '<strong>%{list}</strong> were successfully deleted.',
                customers.length + contacts.length + users.length,
                {
                  list: translatedUnitEnumeration({
                    customers: [customerIDs.length, customers.length],
                    contacts: [contactIDs.length, contacts.length],
                    users: [userIDs.length, users.length],
                  })
                }),
              icon: 'check',
            });
          } else {
            BpToast.show({
              color: 'red',
              title: $gettext('Failed to delete %{list}', { list: translatedUnitEnumeration({
                customers: [customerIDs.length, customers.length],
                contacts: [contactIDs.length, contacts.length],
                users: [userIDs.length, users.length],
              }, { difference: true }) }),
              content: [
                $ngettext(
                  '<strong>%{list}</strong> was successfully deleted.',
                  '<strong>%{list}</strong> were successfully deleted.',
                  customers.length + contacts.length + users.length,
                  {
                    list: translatedUnitEnumeration({
                      customers: [customerIDs.length, customers.length],
                      contacts: [contactIDs.length, contacts.length],
                      users: [userIDs.length, users.length],
                    })
                  }),
                $gettext(
                  'Failed to delete <strong>%{list}</strong>.',
                  {
                    list: translatedUnitEnumeration({
                      customers: [customerIDs.length, customers.length],
                      contacts: [contactIDs.length, contacts.length],
                      users: [userIDs.length, users.length],
                    }, { difference: true })
                  }),
              ].join('<br>'),
              icon: 'xmark',
            });
          }
        } else {
          BpToast.show({
            color: 'rose',
            title: 'You should not see this!',
            content: 'Something went terribly wrong!',
            icon: 'triangle-exclamation',
          });
        }
      } else {
        BpToast.show({
          color: 'red',
          title: $gettext('Failed to delete %{list}', { list: translatedUnitEnumeration({
            customers: customerIDs.length,
            contacts: contactIDs.length,
            users: userIDs.length,
          }, { includeCount: false }) }),
          content: $gettext('Failed to delete <strong>%{list}</strong>.', { list: translatedUnitEnumeration({
            customers: customerIDs.length,
            contacts: contactIDs.length,
            users: userIDs.length,
          }) }),
          icon: 'xmark',
        });
      }
      confirmDelete.value = {
        customers: [],
        contacts: [],
        users: [],
      };
      confirmation.value = false;
    }

    ///-------------------------------------------------------------------
    /// USERS
    ///-------------------------------------------------------------------

    const userStore = useUserStore(true);

    ///-------------------------------------------------------------------
    /// RETURN
    ///-------------------------------------------------------------------

    return {
      actionTooltip,
      confirmation,
      confirmDelete,
      customers,
      onlyActiveSupport,
      impersonateCustomer,
      deleteCustomer,
      getResellerName,
      headers,
      translatedUnitEnumeration,
      localizeDate,
      showDeleteConfirmation,
      store,
      updateCustomer,
      updateOnlyActiveSupport,
      userStore,
    }
  }
});
