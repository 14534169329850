import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "bp-customer-overview" }
const _hoisted_2 = { class: "bp-customer-overview__content-wrapper" }
const _hoisted_3 = { class: "bp-customer-overview__table-prefix" }
const _hoisted_4 = {
  key: 0,
  class: "bp-customer-overview__table-prefix"
}
const _hoisted_5 = { class: "bp-customer-overview__table-prefix" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 2 }
const _hoisted_9 = {
  key: 3,
  class: "bp-customer-overview__confirmation-hint"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_translate = _resolveComponent("translate")!
  const _component_bp_view_header = _resolveComponent("bp-view-header")!
  const _component_bp_icon = _resolveComponent("bp-icon")!
  const _component_bp_button = _resolveComponent("bp-button")!
  const _component_bp_input = _resolveComponent("bp-input")!
  const _component_bp_table = _resolveComponent("bp-table")!
  const _component_bp_window = _resolveComponent("bp-window")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_bp_view_header, {
      class: "bp-customer-overview__header",
      image: "/static/background/app_background_customers.jpg",
      icon: ['fad', 'users']
    }, {
      default: _withCtx(() => [
        _createVNode(_component_translate, null, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode("Customers")
          ])),
          _: 1
        })
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_bp_table, {
        data: _ctx.customers,
        headers: _ctx.headers,
        loading: _ctx.store.isLoading(),
        "default-sorting": "company",
        "fill-height": ""
      }, {
        prefix: _withCtx(({ selection, count }) => [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_bp_icon, { icon: "check" }),
            _createVNode(_component_translate, {
              "translate-plural": "<strong>%{count}</strong> selected customers",
              "translate-n": selection.size,
              "translate-params": { count: selection.size }
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createElementVNode("strong", null, "%{count}", -1),
                _createTextVNode(" selected customer ")
              ])),
              _: 2
            }, 1032, ["translate-n", "translate-params"])
          ]),
          (count < _ctx.store.customers.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_bp_icon, { icon: "filter" }),
                _createVNode(_component_translate, {
                  "translate-plural": "<strong>%{count}</strong> filtered customers",
                  "translate-n": count,
                  "translate-params": { count: count }
                }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createElementVNode("strong", null, "%{count}", -1),
                    _createTextVNode(" filtered customer ")
                  ])),
                  _: 2
                }, 1032, ["translate-n", "translate-params"])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_bp_icon, { icon: "users" }),
            _createVNode(_component_translate, {
              "translate-plural": "<strong>%{count}</strong> customers",
              "translate-n": _ctx.store.customers.length,
              "translate-params": { count: _ctx.store.customers.length }
            }, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createElementVNode("strong", null, "%{count}", -1),
                _createTextVNode(" customer ")
              ])),
              _: 1
            }, 8, ["translate-n", "translate-params"])
          ])
        ]),
        toolbar: _withCtx(({ selection }) => [
          _createVNode(_component_bp_button, {
            icon: "arrows-rotate",
            color: "yellow",
            onClick: _ctx.store.reload
          }, {
            default: _withCtx(() => [
              _createVNode(_component_translate, null, {
                default: _withCtx(() => _cache[6] || (_cache[6] = [
                  _createTextVNode("Refresh")
                ])),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_bp_button, {
            icon: "plus",
            color: "green",
            to: { name: 'admin.customer.new' }
          }, {
            default: _withCtx(() => [
              _createVNode(_component_translate, null, {
                default: _withCtx(() => _cache[7] || (_cache[7] = [
                  _createTextVNode("New")
                ])),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_bp_button, {
            icon: ['far', 'pencil'],
            color: "light-blue",
            disabled: selection.size !== 1,
            onClick: ($event: any) => (_ctx.updateCustomer(Array.from(selection)))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_translate, null, {
                default: _withCtx(() => _cache[8] || (_cache[8] = [
                  _createTextVNode("Edit")
                ])),
                _: 1
              })
            ]),
            _: 2
          }, 1032, ["disabled", "onClick"]),
          _createVNode(_component_bp_button, {
            icon: ['far', 'user-secret'],
            color: "light-blue",
            disabled: selection.size !== 1,
            onClick: ($event: any) => (_ctx.impersonateCustomer(Array.from(selection)))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_translate, null, {
                default: _withCtx(() => _cache[9] || (_cache[9] = [
                  _createTextVNode("Impersonate")
                ])),
                _: 1
              })
            ]),
            _: 2
          }, 1032, ["disabled", "onClick"]),
          _createVNode(_component_bp_input, {
            class: "ml-auto",
            type: "toggle",
            text: _ctx.$gettext('Only active support'),
            "model-value": _ctx.onlyActiveSupport,
            "onUpdate:modelValue": _ctx.updateOnlyActiveSupport
          }, null, 8, ["text", "model-value", "onUpdate:modelValue"]),
          _createVNode(_component_bp_button, {
            icon: ['far', 'trash-can'],
            color: "red",
            disabled: selection.size === 0,
            onClick: ($event: any) => (_ctx.showDeleteConfirmation(Array.from(selection)))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_translate, null, {
                default: _withCtx(() => _cache[10] || (_cache[10] = [
                  _createTextVNode("Delete")
                ])),
                _: 1
              })
            ]),
            _: 2
          }, 1032, ["disabled", "onClick"])
        ]),
        actions: _withCtx(({ item }) => [
          _withDirectives(_createVNode(_component_bp_icon, {
            icon: ['far', 'pencil'],
            onClick: ($event: any) => (_ctx.updateCustomer(item._id))
          }, null, 8, ["onClick"]), [
            [_directive_tooltip, _ctx.actionTooltip(_ctx.$gettext('Edit customer'))]
          ]),
          _withDirectives(_createVNode(_component_bp_icon, {
            icon: ['far', 'user-secret'],
            onClick: ($event: any) => (_ctx.impersonateCustomer(item._id))
          }, null, 8, ["onClick"]), [
            [_directive_tooltip, _ctx.actionTooltip(_ctx.$gettext('Impersonate customer'))]
          ]),
          _withDirectives(_createVNode(_component_bp_icon, {
            icon: ['far', 'trash-can'],
            color: "red",
            onClick: ($event: any) => (_ctx.showDeleteConfirmation(item._id))
          }, null, 8, ["onClick"]), [
            [_directive_tooltip, _ctx.actionTooltip(_ctx.$gettext('Delete customer'))]
          ])
        ]),
        _: 1
      }, 8, ["data", "headers", "loading"])
    ]),
    _createVNode(_component_bp_window, {
      type: "warning",
      modelValue: _ctx.confirmation,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.confirmation) = $event))
    }, {
      header: _withCtx(() => [
        _createVNode(_component_translate, { tag: "h2" }, {
          default: _withCtx(() => _cache[11] || (_cache[11] = [
            _createTextVNode("Delete confirmation")
          ])),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_translate, {
          "translate-params": { list: _ctx.translatedUnitEnumeration({
            customers: _ctx.confirmDelete.customers.length,
            contacts: _ctx.confirmDelete.contacts.length,
            users: _ctx.confirmDelete.users.length,
          }) }
        }, {
          default: _withCtx(() => _cache[12] || (_cache[12] = [
            _createTextVNode("Do you really want to delete "),
            _createElementVNode("strong", null, "%{list}", -1),
            _createTextVNode("?")
          ])),
          _: 1
        }, 8, ["translate-params"]),
        (_ctx.confirmDelete.customers.length > 0)
          ? (_openBlock(), _createElementBlock("table", _hoisted_6, [
              _createElementVNode("thead", null, [
                _createElementVNode("tr", null, [
                  _createVNode(_component_translate, { tag: "th" }, {
                    default: _withCtx(() => _cache[13] || (_cache[13] = [
                      _createTextVNode("Company")
                    ])),
                    _: 1
                  })
                ])
              ]),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.confirmDelete.customers, (customer) => {
                  return (_openBlock(), _createElementBlock("tr", {
                    key: customer._id
                  }, [
                    _createElementVNode("td", null, _toDisplayString(customer.company), 1)
                  ]))
                }), 128))
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.confirmDelete.contacts.length > 0)
          ? (_openBlock(), _createElementBlock("table", _hoisted_7, [
              _createElementVNode("thead", null, [
                _createElementVNode("tr", null, [
                  _createVNode(_component_translate, { tag: "th" }, {
                    default: _withCtx(() => _cache[14] || (_cache[14] = [
                      _createTextVNode("Company name")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_translate, { tag: "th" }, {
                    default: _withCtx(() => _cache[15] || (_cache[15] = [
                      _createTextVNode("Firstname")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_translate, { tag: "th" }, {
                    default: _withCtx(() => _cache[16] || (_cache[16] = [
                      _createTextVNode("Lastname")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_translate, { tag: "th" }, {
                    default: _withCtx(() => _cache[17] || (_cache[17] = [
                      _createTextVNode("Mail")
                    ])),
                    _: 1
                  })
                ])
              ]),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.confirmDelete.contacts, (contact) => {
                  return (_openBlock(), _createElementBlock("tr", {
                    key: contact._id
                  }, [
                    _createElementVNode("td", null, _toDisplayString(contact.companyName), 1),
                    _createElementVNode("td", null, _toDisplayString(contact.forename), 1),
                    _createElementVNode("td", null, _toDisplayString(contact.surname), 1),
                    _createElementVNode("td", null, [
                      _createTextVNode(_toDisplayString(contact.email), 1),
                      _cache[18] || (_cache[18] = _createElementVNode("span", { class: "bp-customer-overview__confirmation-hint" }, " *", -1))
                    ])
                  ]))
                }), 128))
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.confirmDelete.users.length > 0)
          ? (_openBlock(), _createElementBlock("table", _hoisted_8, [
              _createElementVNode("thead", null, [
                _createElementVNode("tr", null, [
                  _createVNode(_component_translate, { tag: "th" }, {
                    default: _withCtx(() => _cache[19] || (_cache[19] = [
                      _createTextVNode("Company name")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_translate, { tag: "th" }, {
                    default: _withCtx(() => _cache[20] || (_cache[20] = [
                      _createTextVNode("Firstname")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_translate, { tag: "th" }, {
                    default: _withCtx(() => _cache[21] || (_cache[21] = [
                      _createTextVNode("Lastname")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_translate, { tag: "th" }, {
                    default: _withCtx(() => _cache[22] || (_cache[22] = [
                      _createTextVNode("Mail")
                    ])),
                    _: 1
                  })
                ])
              ]),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.confirmDelete.users, (user) => {
                  return (_openBlock(), _createElementBlock("tr", {
                    key: user._id
                  }, [
                    _createElementVNode("td", null, _toDisplayString(user.companyName), 1),
                    _createElementVNode("td", null, _toDisplayString(user.forename), 1),
                    _createElementVNode("td", null, _toDisplayString(user.surname), 1),
                    _createElementVNode("td", null, [
                      _createTextVNode(_toDisplayString(user.email), 1),
                      _cache[23] || (_cache[23] = _createElementVNode("span", { class: "bp-customer-overview__confirmation-hint" }, " *", -1))
                    ])
                  ]))
                }), 128))
              ])
            ]))
          : _createCommentVNode("", true),
        ((_ctx.confirmDelete.contacts.length + _ctx.confirmDelete.users.length) > 0)
          ? (_openBlock(), _createElementBlock("p", _hoisted_9, [
              _cache[25] || (_cache[25] = _createTextVNode("* ")),
              _createVNode(_component_translate, {
                tag: "em",
                style: {"font-weight":"normal"},
                "translate-plural": "These <strong>%{list}</strong> are included in the selection and are therefore also deleted.",
                "translate-n": _ctx.confirmDelete.contacts.length + _ctx.confirmDelete.users.length,
                "translate-params": { list: _ctx.translatedUnitEnumeration({
            contacts: _ctx.confirmDelete.contacts.length,
            users: _ctx.confirmDelete.users.length,
          }, { includeCount: false }) }
              }, {
                default: _withCtx(() => _cache[24] || (_cache[24] = [
                  _createTextVNode("This "),
                  _createElementVNode("strong", null, "%{list}", -1),
                  _createTextVNode(" is included in the selection and is therefore also deleted.")
                ])),
                _: 1
              }, 8, ["translate-n", "translate-params"])
            ]))
          : _createCommentVNode("", true)
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_bp_button, {
          icon: "check",
          color: "green",
          onClick: _ctx.deleteCustomer
        }, {
          default: _withCtx(() => [
            _createVNode(_component_translate, null, {
              default: _withCtx(() => _cache[26] || (_cache[26] = [
                _createTextVNode("Confirm")
              ])),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_bp_button, {
          icon: "xmark",
          color: "red",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.confirmation = false))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_translate, null, {
              default: _withCtx(() => _cache[27] || (_cache[27] = [
                _createTextVNode("Cancel")
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}